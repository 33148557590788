import { Link } from "react-router-dom";
import './ErrorPage.css';

function ErrorPage() {
    return (
        <section id="error-section" className="portfolio-section">
            <h2>
                <span>Error: Page Not Found</span>
            </h2>
            <Link to="/">
                <h3>Go Back Home</h3>
            </Link>

        </section>
    )
}

export { ErrorPage };