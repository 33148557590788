import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Introduction } from './Introduction';
import { Skills } from './Skills';
import { Contact } from './Contact';
import { AboutMe } from './AboutMe';
import { ErrorPage } from './ErrorPage';
import { Post } from './Post';
import reportWebVitals from './reportWebVitals';
// React Router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Projects } from './Projects';
import { ProjectItem } from './Projects/ProjectItem';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "/about",
    element: <AboutMe />
  },
  // {
  //   path: "/projects",
  //   element: <Projects>
  //     <ProjectItem project="p1" />
  //     <ProjectItem project="p2" />
  //     <ProjectItem project="p3" />
  //     <ProjectItem project="p4" />
  //   </Projects>
  // },
  // {
  //   path: "/project/:projectId",
  //   element: <Post />
  // }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
