import portrait from './developer.png';
import languagesInterest from './languages.png';
import mentalHealthInteret from './mental-health-advocacy.png';
import selfDevelopmentInterest from './self-development.png';
import './AboutMe.css';

function AboutMe() {
    return (
        <section id="about-section" className="portfolio-section">
            <div id="about-intro-container">
                <div id="about-intro-description">
                    <h2>
                        <span>About Me</span>
                    </h2>
                    <h3 className="about-text">

                        Beyond my technical expertise, <strong>I am deeply committed to personal growth and mental health advocacy</strong>. I actively engage in self-development through literature, drawing insights from books like Mindset by Carol Dweck and Grit by Angela Duckworth. Additionally, <strong>I find great value in contributing to mental health-oriented projects</strong>, whether through volunteering, developing custom apps, or supporting other initiatives.
                        <br />
                        <br />
                        This holistic approach to my work and life not only fuels my creativity but also ensures I <strong>bring empathy, resilience, and adaptability</strong> to every project. In a remote front-end developer role, these qualities translate into <strong>effective communication, strong problem-solving skills, and a proactive work ethic</strong>. I am adept at self-management and maintaining productivity across different time zones, ensuring that I consistently deliver high-quality work that meets project deadlines and exceeds expectations.
                    </h3>
                </div>
                <img src={portrait} id="about-intro-img" />
            </div>
            <div id="about-interests-container">
                <h3 className="midsize-title">
                    Front-End Developer with a Passion for Continuous Growth and Empathy-Driven Projects
                </h3>
                <h4 className="about-text">
                    Leveraging self-development, mental health advocacy, and language learning to enhance remote web development.
                </h4>
                <div id="interests-container">
                    <div className="interest">
                        <img src={selfDevelopmentInterest} />
                        <h5>Self-Development</h5>
                        <h6>Cultivating a Growth Mindset for Professional Excellence</h6>
                        <p className="about-text">
                            I’m dedicated to personal growth, drawing from books like Mindset and Grit to continuously improve my skills and resilience.
                        </p>
                    </div>
                    <div className="interest">
                        <img src={mentalHealthInteret} />
                        <h5>Mental Health Advocacy</h5>
                        <h6>Empathy-Driven Solutions for Digital Wellness</h6>
                        <p className="about-text">
                            I contribute to mental health projects through volunteering and creating custom apps, bringing empathy and balance to my work.
                        </p>
                    </div>
                    <div className="interest">
                        <img src={languagesInterest} />
                        <h5>Language Learning</h5>
                        <h6>Enhancing Global Communication and Collaboration</h6>
                        <p className="about-text">
                            Fluent in English, native in Spanish, and intermediate in French, I bridge cultural gaps and excel in diverse, remote teams.
                        </p>
                    </div>
                </div>
            </div>

        </section>
    )
}

export { AboutMe };