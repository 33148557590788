import reactImg from './Abilities/React.webp';
import htmlImg from './Abilities/HTML.png';
import cssImg from './Abilities/CSS.webp';
import javascriptImg from './Abilities/JavaScript.png';
import phpImg from './Abilities/php.png';
import wordpressImg from './Abilities/WordPress.webp';
import elementorImg from './Abilities/Elementor.webp';
import sqlImg from './Abilities/sql.png';
import scrumImg from './Abilities/scrum.png';
import wooImg from './Abilities/WooCommerce.png';
import gitImg from './Abilities/git.png';
import arduinoImg from './Abilities/arduino.png';
import './Skills.css';



function Skills() {
    return (
        // Section layout
        <section id="skills-section" className="portfolio-section">
            <h2>
                <span>Skills and Work Process</span>
            </h2>
            <h3>I value tools and methodologies that enhance productivity and project management. Here’s an overview of my skills and professional journey:</h3>
            {/* Skills information */}
            <div id="skill-list-wrapper">
                <div className="skill-list-container">
                    <h4 className="skill-title">Relevant Formal <br />Education</h4>
                    <div className="skill-list-description-container">
                        <h4>Associate's Degree in Computer Science</h4>
                        <p>
                            I earned my Associate's Degree in Computer Science from a <strong>fully-accredited American university</strong>, graduating in 2021. While based in Colombia, my English level has allowed me to acquire highly important lessons and viewpoints in computer science in a professional context.
                        </p>
                    </div>

                </div>
                <div className="skill-list-container">
                    <h4 className="skill-title">Spoken and Written <br />Languages</h4>
                    <div className="skill-list-description-container">
                        <h4>Fluent English, Native Spanish, Intermediate French</h4>
                        <p>
                            Language proficiency has been a significant aspect of my education and career. My languages level enhances my ability to work in <strong>diverse, multicultural environments</strong>.
                        </p>
                    </div>

                </div>
                <div className="skill-list-container">
                    <h4 className="skill-title">Planning and <br />  Organization</h4>
                    <div className="skill-list-description-container">
                        <h4>Strong Focus on Strategic Planning and Organization</h4>
                        <p>
                            My experience as a freelance web developer has taught me the value of <strong>structured planning and efficient execution</strong>. By employing agile principles and leveraging custom tools, I consistently deliver projects that exceed client expectations.
                        </p>
                    </div>
                </div>
                <div className="skill-list-container">
                    <h4 className="skill-title">Professional<br /> Vision</h4>
                    <div className="skill-list-description-container">
                        <h4>Path to Full-Stack Development and Cybersecurity</h4>
                        <p>
                            Problem-solving is a core skill I've developed as a front-end developer. I'm driven to deepen my knowledge in <strong>full-stack development and cybersecurity</strong> to further my career goals.
                        </p>
                    </div>

                </div>

                <div className="skill-list-container">
                    <h4 className="skill-title">Relevant <br />  Abilities</h4>
                    <div className="skill-list-description-container">
                        <h4>Fully Front-End Prepared</h4>
                        <div className="abilities-container">
                            {/* Skills stack */}
                            <div className="ability">
                                <img src={htmlImg} />
                                <h5>HTML</h5>
                            </div>
                            <div className="ability">
                                <img src={cssImg} />
                                <h5>CSS</h5>
                            </div>
                            <div className="ability">
                                <img src={javascriptImg} />
                                <h5>JavaScript</h5>
                            </div>
                            <div className="ability">
                                <img src={reactImg} />
                                <h5>React JS</h5>
                            </div>
                            <div className="ability">
                                <img src={phpImg} />
                                <h5>PHP</h5>
                            </div>
                            <div className="ability">
                                <img src={sqlImg} />
                                <h5>SQL</h5>
                            </div>
                            <div className="ability">
                                <img src={wordpressImg} />
                                <h5>WordPress</h5>
                            </div>
                            <div className="ability">
                                <img src={wooImg} id="woocommerce-logo" />
                                {/* <h5>WooCommerce</h5> */}
                            </div>
                            <div className="ability">
                                <img src={elementorImg} />
                                <h5>Elementor</h5>
                            </div>
                            <div className="ability">
                                <img src={gitImg} />
                                <h5>Git - Github</h5>
                            </div>
                            <div className="ability">
                                <img src={scrumImg} />
                                <h5>SCRUM</h5>
                            </div>
                            <div className="ability">
                                <img src={arduinoImg} />
                                <h5>Arduino</h5>
                            </div>
                        </div>
                        {/* <ul className="planning-container">
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>JavaScript</li>
                            <li>React JS</li>
                            <li>RESTapi</li>
                            <li>WordPress</li>
                            <li>PHP</li>
                        </ul> */}
                    </div>
                </div>

            </div>



        </section>
    )
}

export { Skills };