import './Contact.css';
// Import social links icons.
import { IconContext } from "react-icons";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


function Contact() {
    return (
        <section id="contact-section" className="portfolio-section">
            <h2>
                <span>
                    Let's <span className="highlight-text">Build</span> Something Great <span className="highlight-text">Together</span>.
                </span>
            </h2>
            <h3>
                I'm excited to collaborate on impactful projects that push the boundaries of web development. Whether you're looking for <strong>innovative solutions</strong> or a <strong>reliable front-end developer for your team</strong>, I'm ready to bring your ideas to life. <strong>Let's connect and discuss how we can work together to achieve your goals</strong>.
            </h3>
            <div id="email-container">
                {/* Email and copy-to-clipboard button. onClick must be a callback function, not executed yet. */}
                <h3>Contact Me Today</h3>
                <h4>lgmichaeldev@gmail.com</h4>
                {/* <button onClick={event => {
                    navigator.clipboard.writeText("lgmichaeldev@gmail.com");
                    alert("Email copied");
                }} >Copy Email to Clipboard
                </button> */}
            </div>
            <IconContext.Provider value={{ size: "2.15rem", color: "#333" }}>
                <div className="icons-container">
                    <a href="https://github.com/lgmichaeldev" target="_blank">
                        <FaGithub />
                    </a>
                    <a href="https://linkedin.com/in/michael-lopez-lgmichaeldev" target="_blank">
                        <FaLinkedin />
                    </a>

                </div>
            </IconContext.Provider>
        </section>
    )
}

export { Contact };
