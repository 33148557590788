import './App.css';
import { Introduction } from '../Introduction';
import { Projects } from '../Projects';
import { ProjectItem } from '../Projects/ProjectItem';
import { Skills } from '../Skills';
import { Contact } from '../Contact';
import { AboutMe } from '../AboutMe';

function App() {
  return (
    // React fragment in short form.
    <>
    <div className="bg-image">

    </div>
    {/* Used to have the items inside the ProjectList component, and that one inside the Projects component.
        Brought them here to try and see if that would fix the issue of multiple executions of a function
        inside ProjectItem. It did not. */}
      <Introduction />
      {/* <Projects>
          <ProjectItem project="p1"/>
          <ProjectItem project="p2"/>
          <ProjectItem project="p3"/>
          <ProjectItem project="p4"/>
      </Projects> */}
      <Skills />
      <AboutMe />
      <Contact />

    </>

  );
}

export default App;
