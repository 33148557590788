import portrait from '../Assets/portrait.webp';
import './Introduction.css';
import { IconContext } from "react-icons";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


// OBJECTIVES

// - Use keywords (title position, technologies used, experience, show I have a diploma, and if possible, briefly show I am reliable).
// - Be brief.
// - Use a good photo. 

// FUNCTIONS

// - Users can see my information in a legible and brief manner.
// - Users get a good impression of my skills (custom domain, good design).
// - Convey professionalism through style of presentation (more elegant and less flashy). Use humility ('Want to focus on X type of projects to better deepen my Y skillset').

// STATUS
//      24 Juillet 2024
//      Finished components according to planning, will move on to next section.
//      31 Juillet 2024
//      Re-take using new planning framework (fully make section, design included, then move to next one).
//      As of right now, should I move on to the next section, or continue refining this one?

function Introduction() {
    return (
        <section id="introduction-section">
            <div id="profile-description-container">
                <h1>I'm Michael,<br /> Front-End Developer.</h1>
                <h2> Associate's Degree in Computer Science <br /> +150 Sites Sold, Planned and Developed </h2>
                {/* <ul>
                <li>
                    React Developer
                </li>
                <li>
                    WordPress Developer
                </li>
                <li>
                    Problem Solver
                </li>
            </ul> */}
                <p>
                
                    I'm a front-end web developer with almost <strong>4 years of freelance experience</strong>, highly experienced in <strong>CSS, HTML, JavaScript, React, PHP and WordPress</strong>. I've developed and launched <strong>over 150 websites</strong>, enhancing user engagement. Experienced in SCRUM, Git, and GitHub. <strong>Native Spanish speaker, fluent in English, and intermediate in French</strong>, with strong communication and problem-solving skills.
                </p>
                <IconContext.Provider value={{size: "1.85rem", color: "#333" }}>
                    <div className="icons-container">
                        <a href="https://github.com/lgmichaeldev" target="_blank">
                            <FaGithub />
                        </a>
                        <a href="https://linkedin.com/in/michael-lopez-lgmichaeldev" target="_blank">
                          <FaLinkedin />
                        </a>
                        
                    </div>
                </IconContext.Provider>

            </div>
            <div id="profile-img-container">
                <img src={portrait} id="profile-img" />
            </div>

        </section>

    )
}

export { Introduction };